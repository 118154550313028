<template>
  <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.2993 2.16992H12.606V17.8303H21.2993V2.16992Z" fill="#FF5A00"/>
  <path d="M13.1728 10C13.1728 6.79245 14.6847 3.96226 16.9525 2.16981C15.3461 0.849057 13.1728 0 10.905 0C5.32986 0 0.888672 4.43396 0.888672 10C0.888672 15.566 5.32986 20 10.905 20C13.2673 20 15.3461 19.1509 17.047 17.8302C14.6847 15.9434 13.1728 13.1132 13.1728 10Z" fill="#EB001B"/>
  <path d="M33.111 10C33.111 15.4717 28.6698 20 23.0947 20C20.7324 20 18.6535 19.1509 16.9526 17.8302C19.315 16.0377 20.7324 13.2075 20.7324 10C20.7324 6.79245 19.2205 3.96226 16.9526 2.16981C18.6535 0.849057 20.7324 0 23.0947 0C28.6698 0 33.111 4.5283 33.111 10Z" fill="#F79E1B"/>
  </svg>
</template>

<script>

export default {
  name: 'master',
  components: {},
}
</script>
