<template>
  <img src="/assets/dashboard/assets/images/amex.png">
</template>

<script>

export default {
  name: 'amex',
  components: {},
}
</script>
