<template>
  <svg width="54" height="16" viewBox="0 0 54 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.7656 15.7854L21.5091 0.322266H25.7889L23.1552 15.7854H18.7656Z" fill="#00589F"/>
  <path d="M38.7381 0.644295C37.8601 0.322148 36.5433 0 34.8972 0C30.6173 0 27.6544 2.14765 27.5446 5.15436C27.5446 7.4094 29.7394 8.5906 31.2758 9.34228C32.9219 10.094 33.4706 10.6309 33.4706 11.2752C33.4706 12.349 32.1537 12.7785 30.8368 12.7785C29.081 12.7785 28.2031 12.5638 26.7765 11.9195L26.2278 11.7047L25.5693 15.1409C26.557 15.5705 28.4226 16 30.3979 16C34.8972 16 37.8601 13.9597 37.9699 10.7383C37.9699 9.02013 36.8725 7.62416 34.3485 6.55034C32.8121 5.79866 31.9342 5.36913 31.9342 4.61745C32.044 3.97315 32.9219 3.22148 34.568 3.22148C35.9946 3.22148 36.9822 3.54362 37.7504 3.86577L38.1894 4.08054L38.7381 0.644295Z" fill="#00589F"/>
  <path d="M49.8215 0.322266H46.5293C45.5416 0.322266 44.7734 0.644413 44.2247 1.61086L37.8599 15.7854H42.3592C42.3592 15.7854 43.1274 13.8525 43.2371 13.4229C43.7858 13.4229 48.1754 13.4229 48.7241 13.4229C48.8338 13.9599 49.2728 15.7854 49.2728 15.7854H53.3331L49.8215 0.322266ZM44.554 10.3088C44.8832 9.44978 46.3098 6.01354 46.3098 6.01354C46.3098 6.01354 46.639 5.15448 46.8585 4.51019L47.1877 5.79878C47.1877 5.79878 48.0656 9.44978 48.1754 10.3088H44.554Z" fill="#00589F"/>
  <path d="M15.1442 0.322266L10.9741 10.8458L10.5352 8.6981C9.767 6.22831 7.243 3.54374 4.49951 2.14777L8.34039 15.678H12.9494L19.7533 0.322266H15.1442Z" fill="#00589F"/>
  <path d="M7.02332 0.322266H0V0.644413C5.37723 1.933 8.99863 5.0471 10.535 8.80549L8.99863 1.61086C8.66941 0.644413 8.01097 0.322266 7.02332 0.322266Z" fill="#F9A51A"/>
  </svg>
</template>

<script>

export default {
  name: 'visa',
  components: {},
}
</script>
